.App {
    background-color: #E7EBF0;
    height: 100vh;
}

.MuiContainer-root {
    max-width: unset !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
}

.page__container {
    background-color: white;
}